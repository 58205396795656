<style lang="scss" scoped>
    .yellow-warn{
        p{
            background: #FF9500;
            color: #ffffff;
            font-size: 12px;
            text-align: center;
            height: 26px;
            line-height: 26px;
        }
    }
</style>
<template>

    <div class="yellow-warn">

        <p>{{content}}</p>
    </div>

</template>

<script type="text/ecmascript-6">
    export default {
        //定义模版数据
        data() {
            let data
            return {
                data
            }
        },
        props: ['content'],
        //计算属性
        computed: {},
        //主件被加载完成
        mounted: function () {

        },
        //定义事件方法
        methods: {},
        //监听模版变量
        watch: {}

    }
</script>