<style lang="scss" scoped>
    .section-login {
        padding: 30px;
        .login-header {
            line-height: 30px;
            .header-title {
                float: left;
                font-family: PingFangSC-Regular;
                font-size: 24px;
                color: #666666;
            }
            .reg-forget-pwd {
                float: right;
                font-family: PingFangSC-Medium;
                font-size: 16px;
                color: #319BF7;
                padding-top: 6px;
                span {
                    font-weight: 600;
                    float: left;
                    line-height: 20px;
                    cursor: pointer;
                }
                .header-reg {
                    margin-right: 15px;
                    position: relative;
                    &:before {
                        content: '';
                        position: absolute;
                        background: #CCCCCC;
                        width: 1px;
                        height: 16px;
                        right: -15px;
                        top: 50%;
                        margin-top: -8px;
                    }
                }
                .header-forget-pwd {
                    margin-left: 15px;
                }

            }
        }
        .login-section {
            padding-top: 30px;
            .login-phone-num {

                &.el-form-item {
                    margin-bottom: 20px;
                }
                &.is-error {
                    .el-input__prefix {
                        color: #f56c6c;
                    }
                }

            }
            .login-password {
                &.el-form-item {
                    margin-bottom: 20px;
                }

            }
            .login-remember-pwd {
                &.el-form-item {
                    margin: 10px 0 30px 0;
                }
            }
            .section-footer {
                .el-button {
                    font-size: 16px;
                    width: 100%;
                    padding: 17px 20px;
                    border: 0;
                    background-image: linear-gradient(90deg, #319BF7 0%, #31D1F7 100%);
                }

            }
            .manage-backstage-footer {
                p {
                    font-family: PingFangSC-Regular;
                    font-size: 20px;
                    color: #666666;
                }
                .el-button + .el-button {
                    margin-left: 0;
                }
                .el-button {
                    margin-top: 15px;
                    &.backstage-footer-2 {
                        background-image: linear-gradient(90deg, #25D262 0%, #66E3B7 100%);
                    }
                    &.backstage-footer-0 {
                        background-image: linear-gradient(90deg, #082D50 0%, #3F556B 100%);
                    }

                }

            }
        }

    }

    .login-operation {
        position: relative;
        width: 100%;
        height: 100%;
        background: #ffffff;
    }

    .login-operation /deep/ {
        .login-role-pop {
            position: absolute;
            bottom: 0;
            left: 0;
            &.el-dialog {
                margin: 0;
                width: 100%;
            }
        }
        .mod-login-role {
            .el-dialog__header {
                display: none;
                opacity: 0;
            }
            .el-dialog__body {
                padding: 0;
            }

        }
        .login-phone-num, .login-password {
            .el-input__inner {
                margin-top: 4px;
                height: 50px;
                /*line-height: 50px;*/
            }
        }
        .login-role-list {
            text-align: center;
            .el-button {
                width: 100%;
                border-radius: 0;
                border: 0;
                border-bottom: 1px solid #f8f8f8;
                font-size: 18px;
                &:last-child {
                    border-bottom: 0;
                }
            }
            .el-button + .el-button {
                margin-left: 0;
            }
        }

    }
</style>
<style lang="scss">
    .login-section {
        .el-input__prefix, .el-input__icon, .el-input__suffix {
            transition: all .1s;
            -webkit-transition: all .1s;
        }
        .is-icon-focus {
            .el-input__prefix {
                color: #409EFF;
            }
            .el-input__suffix {
                color: #44B93B;
            }

        }
        .el-form-item__content {
            line-height: normal
        }
        .login-phone-num {
            &.is-error {
                .el-input__prefix {
                    color: #f56c6c;
                }
            }
            &.is-success {
                .el-input__inner {
                    border-color: #319BF7;
                }
                .el-input__prefix {
                    color: #319BF7;
                }
            }

        }
        .login-remember-pwd {
            .el-form-item__content {
                line-height: 20px;
            }
            .el-checkbox {
                font-weight: normal;
            }

        }

        .login-password {
            .el-input__suffix {
                top: 1px;
                right: 10px;
                .login-pwd-type {
                    &.icon-display-pwd {
                        font-size: 9px;
                    }
                    &.icon-not-display-pwd {
                        font-size: 11px;
                    }

                }
            }
            &.is-error {
                .el-input__prefix {
                    color: #f56c6c;
                }
                .el-input__suffix {
                    color: #f56c6c;
                }
            }
            &.is-success {
                .el-input__inner {
                    border-color: #319BF7;
                }
                .el-input__prefix {
                    color: #319BF7;
                }
            }

        }

    }

</style>
<template>

    <div class="login-operation">
        <yellow-warn content="请使用900游管理系统账号登录，如没有账号，请至电脑端申请注册"></yellow-warn>
        <div class="section-login">
            <div class="login-header">
                <ul class="clear">
                    <li class="header-title">
                        登录
                    </li>
                    <!--<li class="reg-forget-pwd clear">-->
                    <!--<span class="header-forget-pwd" @click="goTo()">忘记密码</span>-->
                    <!--</li>-->
                </ul>
            </div>
            <div class="login-section">
                <el-form :model="form" :rules="rules" ref="loginForm">
                    <el-form-item :label-width="'0px'" prop="loginName" class="login-phone-num">
                        <el-input
                                :class="{'is-icon-focus':isLoginNameFocus}"
                                placeholder="手机号码"
                                type="tel"
                                @focus="isLoginNameFocus=true"
                                @blur="isLoginNameFocus=false"
                                prefix-icon="icon icon-phone"
                                :maxlength="11"
                                v-model="form.loginName"
                                auto-complete="off">
                        </el-input>
                    </el-form-item>
                    <el-form-item :label-width="'0px'" prop="loginPwd" class="login-password">
                        <el-input
                                :type="loginPwd.type"
                                :class="{'is-icon-focus':loginPwd.isFocus}"
                                placeholder="密码"
                                @focus="loginPwd.isFocus=true"
                                @blur="loginPwd.isFocus=false"
                                prefix-icon="icon icon-password"
                                suffix-icon="icon"
                                v-model="form.loginPwd"
                                :minlength="6"
                                :maxlength="20"
                                auto-complete="off">
                            <i slot="suffix"
                               class="icon login-pwd-type"
                               @click="changePwdType"
                               :class="{'icon-display-pwd':loginPwd.type==='text',
                               'icon-not-display-pwd':loginPwd.type!='text'}">
                            </i>
                        </el-input>
                    </el-form-item>
                    <div class="section-footer">
                        <!--<el-button type="primary" @click="handRolePop()">登录22</el-button>-->
                        <el-button type="primary" @click="handleLogin()">登 录</el-button>
                    </div>
                </el-form>
            </div>
        </div>

        <el-dialog
                :close-on-click-modal="false"
                :show-close="false"
                :center="true"
                custom-class="login-role-pop"
                class="mod-login-role"
                :visible.sync="roleShow">
            <div class="login-role-list">
                <el-button v-for="(list, index) in rolesList" :key="index"
                           @click="selectRole(list)">{{list.roleName}}
                </el-button>
            </div>
        </el-dialog>
    </div>

</template>

<script type="text/ecmascript-6">
    import yellowWarn from '../../components/yellowWarn'
    import localStorage from '@/common/js/localStorage';
    import {backendLogin} from '@/components/account/service'
    import {isCellphone} from '@/common/js/Utils';
    import {Toast} from 'mint-ui';

    export default {
        //定义模版数据
        data() {
            const account = localStorage.getObject("account") || {};
            return {
                isLoginNameFocus: false,
                loginPwd: {
                    isFocus: false,
                    type: 'password'
                },
                rolesList: [],
                form: {
                    loginName: account.loginName,
                    loginPwd: account.loginPwd
                },
                pwdChecked: true,
                rules: {
                    loginName: [
                        {
                            validator: (rule, value, callback) => {
                                if (!value)
                                    callback(new Error('请输入手机号码'));
                                else if (!isCellphone(value))
                                    callback(new Error('手机号格式错误'));
                                else
                                    callback();
                            }, trigger: 'blur'
                        }
                    ],
                    loginPwd: [
                        {required: true, message: '请填写密码', trigger: 'blur'},
                        {min: 6, max: 20, message: '密码不能小于6位', trigger: 'blur'}
                    ]
                },
                chooseList: [],
                roleShow: false
            }
        },
        components: {
            yellowWarn
        },
        //计算属性
        computed: {},
        //主件被加载完成
        mounted: function () {

        },
        //定义事件方法
        methods: {
            changePwdType() {
                this.loginPwd.type = this.loginPwd.type === 'text' ? 'password' : 'text';
            },
            handRolePop() {
                this.roleShow = true
            },
            handleLogin(choose) {
                this.$refs["loginForm"].validate(async (valid) => {
                    if (valid) {
                        this.loadingShow();
                        try {
                            if (choose)
                                this.form.loginUsertypeList = [choose];
                            else
                                delete  this.form.loginUsertypeList;
                            const ret = await backendLogin(this, this.form);
                            this.loadingHide();

                            if (ret.success) {
                                //选择角色
                                if (ret.data.chooseList.length > 0) {
                                    this.rolesList = ret.data.chooseList;
                                    // ret.data.chooseList.forEach(item => {
                                    //     if (item.refType == 1) {
                                    //         this.handleLogin(item)
                                    //     }
                                    // })
                                    this.loadingHide();
                                    this.roleShow = true;
                                    return false;
                                }
                                this.$store.dispatch("CHANGE_USER_LOGIN_INFO", ret.data);
                                this.cookieStorage.set("eTrip_token", ret.data.token, 2);
                                this.localStorage.setObject("loginUserInfo", ret.data);
                                // this.$store.dispatch("CHANGE_LOGIN_OR_REGISTER", {
                                //     type: 0,
                                //     show: false
                                // });
                                //记住账户密码
                                if (this.pwdChecked)
                                    localStorage.setObject("account", this.form)
                                else
                                    localStorage.setObject("account", {
                                        loginName: this.from.loginName
                                    })
                                this.chooseList = [];
                                Toast("登录成功!");
                                this.roleShow = false;
                                const nextPath = this.sessionStorage.get("nextPath") || '/home';
                                if (nextPath) {
                                    this.sessionStorage.removeItem("nextPath");
                                    this.$router.replace(nextPath)
                                }
                            } else if (ret.errors && ret.errors.length > 0 && ret.errors[0].code === 'loginuserPwdErr') {
                                this.loadingHide();
                                return Toast('用户名和密码不匹配!');
                            } else if (ret.errors && ret.errors.length > 0 && ret.errors[0].code === 'loginuserNotExist') {
                                this.loadingHide();
                                return Toast('登录用户不存在!');
                            } else if (ret.errors[0].message)
                                return Toast(ret.errors[0].message);
                            else
                                return Toast('抱歉，登录失败!');
                        } catch (error) {
                            this.loadingHide();
                        }
                        this.loadingHide();
                    }
                })
            },
            goTo() {
                this.$router.push({name: 'forgetPassword'})
            },
            selectRole(list) {
                this.handleLogin(list)
            }
        },
        //监听模版变量
        watch: {}

    }
</script>
